var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$apollo.loading && !_vm.savedValidation)?_c('Loading'):[_c('v-card-text',{attrs:{"id":"validations-edit__card-text"}},[_c('Fields',{attrs:{"id":"validation-edit__component-fields","schema":{
          name: {
            type: 'string',
            label: 'Nombre',
            description: 'Debe ser único y reconocible.',
            sizeSmall: 12,
            sizeLarge: 6,
            newDesign: true
          },
          validationTypeId: {
            type: 'select',
            __graphQLType: 'ID',
            label: 'Tipo de Validación',
            fieldOptions: {
              options: _vm.availableValidationTypes
            },
            sizeSmall: 12,
            sizeLarge: 6,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}}),(_vm.validationType)?[_c('h1',{staticClass:"headline font-weight-bold"},[_vm._v("Opciones de Validación")]),_c('v-container',{attrs:{"fluid":""}},[_c('OptionFields',{attrs:{"id":"validation-edit__option-fields","schema":_vm.validationType.optionsParams,"disabled":_vm.$apollo.loading || _vm.saving,"environmentVariables":{ environmentId: _vm.environmentId },"allowedValueTypes":['none', 'fixed', 'parameter', 'indicator'],"validationErrors":{}},model:{value:(_vm.validationOptions),callback:function ($$v) {_vm.validationOptions=$$v},expression:"validationOptions"}})],1)]:_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Validación"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateSavedValidation}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }